<template>
  <div class="page">
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :finishedText="finishedText"
    >
      <div
        class="item"
        @click="toDetail(item)"
        v-for="(item, index) in listData"
        :key="index"
      >
        <div class="name">{{ item.name }}</div>
        <span class="tag">{{ item.teacher }}</span>
        <div class="b-box">
          <div class="box-l">{{ item.date }} {{ item.time }}</div>
          <div class="box-r">
            <div class="purpleBtn" v-if="item.status == '2'">
              <img src="./img/goon.png" />
              <div>进入直播</div>
            </div>
            <div class="whiteBtn" v-else-if="item.status == '3'">
              <img src="./img/rePlay.png" />
              <div>直播回放</div>
            </div>
            <div class="greyBtn" v-else-if="item.status == '1'">
              <img src="./img/wait.png" />
              <div>待开始</div>
            </div>
          </div>
        </div>
      </div>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  addOrUpdateInfoUrl,
  deleteByIdUrl,
  getListUrl,
  getDetailsUrl,
} from "./api.js";
export default {
  name: "liveBroadcast",
  data() {
    return {
      isLoading: false,
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [], // 列表数据集合
      curPage: 1,
      pageSize: 20,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  methods: {
    toDetail(item) {
      if (item.type == 1) {
        window.location.href = item.livestreaming;
      } else {
        if (item.status == "1") {
          this.$toast("暂未开始，敬请期待~");
          return;
        }
        this.$router.push({
          name: "liveBroadcastDetail",
          query: {
            id: item.topicId,
            detailId: item.id,
          },
        });
      }
    },
    onLoad() {
      this.$axios
        .get(getListUrl, {
          params: {
            tenantId: this.tenantId,
            curPage: this.curPage,
            pageSize: this.pageSize,
            houseId: this.houseId || this.communityId || undefined,
          },
        })
        .then((res) => {
          if (this.curPage >= res.data.pages) {
            this.finished = true;
          } else {
            this.finished = false;
            this.$refs.list.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.listData = [];
            this.finishedText = "";
            return;
          }
          if (res.code === 200 && res.data && res.data.records) {
            if (this.curPage === 1) {
              this.listData = res.data.records;
            } else {
              const list = this.listData.map((v) => v.id);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.id) || this.listData.push(item);
              });
            }
            this.curPage++;
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  background: #fbfbfb;
  padding: 30px;
  box-sizing: border-box;
  .item {
    width: 100%;
    height: 240px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
    border-radius: 16px;
    margin-bottom: 24px;
    padding: 36px 28px 0 34px;
    box-sizing: border-box;
    .name {
      font-size: 32px;
      font-weight: 600;
      color: #333333;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-bottom: 16px;
      box-sizing: border-box;
    }
    .tag {
      font-size: 24px;
      color: #fe6f16;
      padding: 4px 6px;
      background: #fff3eb;
      border-radius: 4px;
      border: 2px solid #ffc7a4;
    }
    .b-box {
      width: 100%;
      height: 58px;
      display: flex;
      align-items: center;
      .box-l {
        flex: 1;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.5);
      }
      .box-r {
        width: 180px;
        height: 100%;
        .purpleBtn {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          background: linear-gradient(180deg, #8e8aff 0%, #6763ff 100%);
          border-radius: 8px;
          padding-left: 26px;
          box-sizing: border-box;
          img {
            width: 26px;
            margin-right: 12px;
          }
          div {
            font-size: 24px;
            font-weight: 600;
            color: #ffffff;
          }
        }
        .whiteBtn {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          background: #f3f3ff;
          border-radius: 8px;
          padding-left: 26px;
          box-sizing: border-box;
          border: 2px solid #6864ff;
          img {
            width: 26px;
            margin-right: 12px;
          }
          div {
            font-size: 24px;
            font-weight: 600;
            color: #6864ff;
          }
        }
        .greyBtn {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          background: linear-gradient(180deg, #ededed 0%, #dfdfdf 100%);
          border-radius: 8px;
          padding-left: 26px;
          box-sizing: border-box;
          img {
            width: 26px;
            margin-right: 12px;
          }
          div {
            font-size: 24px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }
}
</style>
